<template>
  <div class="terminal-management" ref="terminalManagement">
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddTerminal"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="importExcel"
        v-if="$store.state.menu.nowMenuList.indexOf('导入') >= 0"
        >导入</el-button
      >
    </div>
    <div class="form-area" ref="form">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="72px"
      >
        <el-form-item label="终端号：" prop="deviceNo">
          <el-input
            v-model="form.deviceNo"
            placeholder="请输入终端号"
          ></el-input>
        </el-form-item>

        <el-form-item label="车牌号：" prop="cph">
          <el-input v-model="form.cph" placeholder="请输入车牌号"></el-input>
        </el-form-item>

        <el-form-item label="所属企业：" prop="company">
          <companySelect
            :clearable="true"
            :companyTree="companyList"
            :valueName="valueName"
            :value="value"
            @getValue="getGroupId"
          ></companySelect>
        </el-form-item>

        <el-form-item label="设备型号：" prop="deviceModelId">
          <el-select clearable v-model.trim="form.deviceModelId">
            <el-option
              v-for="(item, key) in deviceModelIdList"
              :label="item.deviceName"
              :value="item.id"
              :key="key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="onSearchClick"
            >查询</el-button
          >
          <el-button
            type="primary"
            size="small"
            :disabled="tableData.length === 0"
            :loading="downLoadStatus"
            @click="exportData"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      stripe
      :height="tableHeight"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="deviceNo"
        label=" 终端号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="serialNo"
        label="自编号"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属企业"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="cph" label="车牌号"></el-table-column>
      <el-table-column prop="deviceMac" label="MAC地址"></el-table-column>

      <el-table-column
        prop="deviceName"
        label="设备型号"
        width="140"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="入库时间"
        width="180"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            @click="onUpdateTerminal(scope.row)"
            type="text"
            size="small"
            sort="primary"
            v-if="$store.state.menu.nowMenuList.indexOf('编辑') >= 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            sort="danger"
            @click="onDeleteTerminal(scope.row)"
            v-if="$store.state.menu.nowMenuList.indexOf('删除') >= 0"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      @close="onDialogClose"
      width="30%"
    >
      <div>
        <div class="body">
          <el-form
            :model="addForm"
            :rules="rules"
            ref="addForm"
            label-width="110px"
            label-position="left"
            class="demo-form dialog-form"
          >
            <el-form-item label="自编号：" prop="serialNo">
              <el-input
                v-model.trim="addForm.serialNo"
                placeholder="请输入设备自编号"
              ></el-input>
            </el-form-item>

            <el-form-item label="终端号：" prop="deviceNo">
              <el-input
                v-model.trim="addForm.deviceNo"
                :disabled="isDeviceNoEdit"
                placeholder="请输入终端号"
              ></el-input>
            </el-form-item>

            <el-form-item label="设备型号：" prop="deviceModelId">
              <el-select v-model.trim="addForm.deviceModelId">
                <el-option
                  v-for="(item, key) in deviceModelIdList"
                  :label="item.deviceName"
                  :value="item.id"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="所属企业：" prop="companyId">
              <companySelect
                ref="companySelect"
                :clearable="true"
                :companyTree="companyList"
                :valueName="valueNameAdd"
                :value="valueAdd"
                @getValue="getAddGroupId"
              ></companySelect>
            </el-form-item>

            <el-form-item label="MAC地址：" prop="deviceMac">
              <el-input
                v-model.trim="addForm.deviceMac"
                placeholder="请输入MAC地址"
              ></el-input>
            </el-form-item>

            <el-form-item label="磁盘序号：" prop="disk">
              <el-input
                v-model.trim="addForm.disk"
                placeholder="请输入磁盘序号"
              ></el-input>
            </el-form-item>

            <el-form-item label="SIM卡号：" prop="sim">
              <el-input
                v-model.trim="addForm.sim"
                placeholder="请输入SIM卡号"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="onSave"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 导入 -->
    <el-dialog title="导入" :visible.sync="downVisible" width="30%">
      <uploadFile
        @uploadSuccess="uploadSuccess"
        url="base/device/importDevice"
        class="import"
        :type="2"
      ></uploadFile>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDeviceList,
  insertDevice,
  deleteDevice,
  updateDevice,
  getBusinessCompanyTree,
  queryAllDeviceModel
} from '@/api/lib/api.js'
import uploadFile from '@/components/uploadFile/uploadFile.vue'
import companySelect from '@/components/treeSelect/companySelect.vue'

import { getCurent, formatDate } from '@/common/utils/index'
import { addExcelExport } from '@/api/lib/refreshAlarm.js'

export default {
  name: 'terminalManagement',
  components: {
    uploadFile,
    companySelect
  },
  data () {
    var checkRangeNumber = (rule, value, callback) => {
      const regEn = /^[0-9]{0,20}$/
      if (value === '') {
        callback()
      } else {
        if (!regEn.test(value)) {
          callback(new Error('请输入不超过20位数字'))
        } else {
          callback()
        }
      }
    }
    return {
      tableHeight: 0,
      tableData: [],
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      form: {
        deviceNo: '',
        deviceModelId: '',
        cph: '',
        companyId: '',
        currentPage: 1,
        pageSize: 10
      },
      addForm: {
        serialNo: '',
        deviceNo: '',
        sim: '',
        disk: '',
        deviceMac: '',
        deviceModelId: '',
        companyId: ''
      },
      rules: {
        serialNo: [
          { required: true, message: '请输入终端自编号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        deviceNo: [
          { required: true, message: '请输入终端号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        sim: [
          { required: true, message: '请输入SIM卡号', trigger: 'blur' },
          { validator: checkRangeNumber, trigger: 'blur' }
        ],
        deviceModelId: [
          { required: true, message: '请选择设备型号', trigger: 'change' }
        ],
        companyId: [
          { required: true, message: '请选择所属公司', trigger: 'blur' }
        ]
      },
      total: 0,
      multipleSelection: [],
      title: '',
      deviceModelIdList: [],
      companyList: [],
      isDeviceNoEdit: false,
      dialogVisible: false,
      editLoading: false,
      exportForm: {},
      downLoadStatus: false,
      downVisible: false
    }
  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.terminalManagement.clientHeight
      const buttonsHeight = this.$refs.functionButtons.clientHeight
      const formHeight = this.$refs.form.clientHeight
      const paginationHeight = this.$refs.pagination.clientHeight
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        paginationHeight -
        10 -
        16
    },
    //导出
    exportData () {
      this.downLoadStatus = true
      let data = {
        baseUrl: 'base',
        userId: sessionStorage.getItem('userId'),
        createTime: formatDate(new Date()),
        fileName: `终端信息-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: '/device/deviceExport'
      }
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg)
          this.downLoadStatus = false
          this.$store.dispatch('setRefreshStatus', true)
        })
        .catch(() => {
          this.downLoadStatus = false
        })
    },
    onSearchClick () {
      this.form.currentPage = 1
      this.onSearch()
    },
    onSearch () {
      let {
        deviceNo,
        deviceModelId,
        cph,
        companyId,
        currentPage,
        pageSize
      } = this.form
      let data = {
        deviceNo: deviceNo,
        deviceModelId: deviceModelId || null,
        cph: cph,
        companyId: companyId || null,
        currentPage: currentPage,
        pageSize: pageSize
      }
      getDeviceList(data).then((res) => {
        if (res.code === 1000) {
          this.tableData = res.data.list
          this.total = res.data.total
          if (!res.data.list.length && this.form.currentPage !== 1) {
            // 如果当前页的数据为0则设置页面为前一页 但是确保当前页不为1
            this.form.currentPage -= 1
            this.onSearch()
          }
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg)
        }
        this.exportForm = { ...data }
      })
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page
      this.onSearch()
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size
      this.form.currentPage = 1
      this.onSearch()
    },

    // 新增终端
    onAddTerminal () {
      this.title = '新增终端'
      this.isDeviceNoEdit = false
      this.dialogVisible = true
    },
    // 点击表格查看按钮
    onUpdateTerminal (row) {
      this.title = '修改终端信息'
      this.$nextTick(() => {
        this.addForm = { ...row }
        this.addForm.deviceModelId = row.deviceModelId * 1
        this.valueAdd = row.companyId
        this.valueNameAdd = row.companyName
        if (this.addForm.cph) {
          this.isDeviceNoEdit = true
        } else {
          this.isDeviceNoEdit = false
        }
      })
      this.dialogVisible = true
    },
    // 表格多选
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    // 删除终端数据
    onDeleteTerminal (row) {
      const h = this.$createElement
      this.$msgbox({
        title: '消息',
        type: 'warning',
        message: h('p', null, [
          h('span', null, '您将删除终端 '),
          h('span', { style: 'color: #059370' }, row.name),
          h('span', null, '  ，是否删除？')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteDevice(row.id).then((res) => {
            if (res.code === 1000) {
              this.$message.success('删除成功')
              this.onSearch()
            } else {
              this.$message.error(res.msg)
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          })
        })
    },

    // 关闭弹窗
    onDialogClose () {
      this.$refs.companySelect.clearHandle()
      this.$refs.addForm.resetFields()
      this.dialogVisible = false
      this.valueAdd = null
      this.valueNameAdd = ''
      this.addForm = {
        serialNo: '',
        deviceNo: '',
        sim: '',
        deviceType: '',
        deviceModel: '',
        companyId: ''
      }
    },
    // 新增/修改终端
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.editLoading = true
          const data = {
            ...this.addForm
          }
          if (this.title.indexOf('新增') !== -1) {
            insertDevice(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('新增成功')
                this.closeDialog()
                this.onSearch()
              } else {
                this.$message.error(res.msg)
              }
              this.editLoading = false
            })
          } else {
            updateDevice(data).then((res) => {
              if (res.code === 1000) {
                this.$message.success('修改成功')
                this.closeDialog()
                this.onSearch()
              } else {
                this.$message.error(res.msg)
              }
              this.editLoading = false
            })
          }
        }
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.$refs.addForm.resetFields()
      this.dialogVisible = false
      this.addForm = {
        serialNo: '',
        deviceNo: '',
        sim: '',
        deviceType: '',
        deviceModel: '',
        companyId: ''
      }
    },
    getGroupId (v) {
      this.form.companyId = v
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    // 获取终端类型
    getAllDeviceModelList () {
      queryAllDeviceModel().then((res) => {
        if (res.code === 1000) {
          this.deviceModelIdList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取终端型号类型
    getDeviceModelList () {
      queryUserGroupTypeList({ parentId: 156 }).then((res) => {
        if (res.code === 1000) {
          this.deviceModelList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    visibleStartDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.form.endTime) {
            return new Date(time) > new Date(self.form.endTime)
          } else {
            return false
          }
        }
      }
    },
    visibleEndDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.form.beginTime) {
            return new Date(time) < new Date(self.form.beginTime)
          } else {
            return false
          }
        }
      }
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    //导入
    importExcel () {
      this.downVisible = true
    },
    // 文件上传成功
    uploadSuccess () {
      this.downVisible = false
      this.onSearch()
    }
  },
  created () {
    this.onSearch()
    this.getCompanyList()
    this.getAllDeviceModelList()
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight()
      window.addEventListener('resize', this.computeHeight, false)
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  }
}
</script>

<style lang="scss" scoped>
.terminal-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  @include themify() {
  }
}

.theme-project-resourse {
  .terminal-management {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
